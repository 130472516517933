import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { getUsers, getUserCount } from "../../actions/user/userActions";
import { formatDate } from "../../actions/momentActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserTable from "./UserTable";
import AddForm from "./AddForm";
import ReduxButton from "../../components/ReduxButton";

const styles = theme => ({
    addWrapper: {
        padding: 10
    },
    searchText: {
        display: "inline-block",
        marginTop: 10,
        marginLeft: 5
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    }
});

export class Customer extends React.Component {
    state = {
        open: false,
        users: [],
        loading: false
    };

    async componentDidMount() {
        await this.getUserCount();
        await this.getUsers();
    }

    handleOpen = () => {
        this.setState({ open: true, customer: null });
    };

    getUserCount = async () => {
        try {
            const users = await this.props.getUserCount();
            console.log("getUserCount :: ", users);
        } catch (e) {}
    };

    getUsers = async () => {
        try {
            this.setState({ loading: true });
            const users = await this.props.getUsers();
            console.log("users :: ", users);
            if (users && Array.isArray(users)) {
                this.setState({ users });
            }
            this.setState({ loading: false });
        } catch (e) {}
    };

    handleClose = () => {
        this.setState({ open: false });
        this.getUsers();
    };

    handleEdit = customer => {
        this.setState({ open: true, customer });
    };

    render() {
        const { classes } = this.props;
        const { open, loading } = this.state;
        const users = this.state.users || [];
        const user = this.state.user || {};

        return (
            <div>
                {open && (
                    <AddForm
                        open={open}
                        user={user}
                        handleClose={this.handleClose}
                    />
                )}
                <Grid container spacing={0} className={classes.addWrapper}>
                    <Grid item xs={6} />
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <ReduxButton
                            width
                            onClick={this.handleOpen}
                            label={"ADD"}
                        />
                    </Grid>
                </Grid>
                {loading ? (
                    <div className={classes.grow}>
                        <CircularProgress />
                    </div>
                ) : (
                    <UserTable
                        handleEdit={this.handleEdit}
                        items={users}
                        formatDate={this.props.formatDate}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        customers: _get(state, "customerData.customers", [])
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getUsers,
            getUserCount,
            formatDate
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Customer)
);
