import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import {
    createInvoice,
    updateInvoice
} from "../../actions/invoice/invoiceActions";
import { getRepairs } from "../../actions/repair/repairActions";
import { getCustomers } from "../../actions/customer/customerActions";
import { validate } from "./validate";
import Grid from "@material-ui/core/Grid";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import InputDateBox from "../../components/InputDateBox";
import Error from "../Error";
import Success from "../Success";
import { INVOICE_STATUS } from "../../constants";

const styles = theme => ({
    wrapper: {},
    centerButtons: {
        padding: 20,
        textAlign: "center"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    DialogActions: {
        padding: 20,
        justifyContent: "center",
        flexGrow: 1
    }
});

export class AddForm extends React.Component {
    state = {
        loading: false
    };

    componentDidMount() {
        this.getRepairs()
        this.getCustomers()
    }

    getRepairs = async () => {
        try {
            const repairs = await this.props.getRepairs();
            if (repairs && Array.isArray(repairs)) {
                this.setState({ repairs });
            }
        } catch (e) { }
    };
    getCustomers = async () => {
        try {
            const customers = await this.props.getCustomers();
            if (customers && Array.isArray(customers)) {
                this.setState({ customers });
            }
        } catch (e) { }
    };

    handleSubmit = async values => {
        console.log(values);
        const invoice = this.props.invoice || {};

        this.setState({ loading: true });
        try {
            values.accountId = this.props.accountId

            let response;
            if (invoice && invoice.id) {
                // values.id = invoice.id;
                response = await this.props.updateInvoice(values);
            } else {
                response = await this.props.createInvoice(values);
            }

            if (response && response.id) {
                this.setState({ successMessage: "Saved sucessfully" });
                setTimeout(() => this.props.handleClose(), 1000);
            } else {
                this.setState({
                    errorMessage: "Not able to save, please again."
                });
            }
        } catch (e) {
            console.log(e);
            this.setState({ errorMessage: "Not able to save, please again." });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { classes } = this.props;
        const invoice = this.props.invoice || {};
        const { loading, errorMessage, successMessage, repairs, customers } = this.state;
        const isSmallScreen = window.innerWidth < 400;

        const initialValues = {
            accountId: "",
            customerId: "",
            status: "NEW",
            repairs: [],
            rate: {
                amount: ""
            },
            dueTm: null,
            items: [],
            ...invoice
        };

        return (
            <Dialog
                fullScreen={isSmallScreen}
                fullWidth={true}
                maxWidth={"md"}
                className={classes.wrapper}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography>
                        {invoice.id ? "EDIT INVOICE" : "ADD INVOICE"}
                    </Typography>
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validate={validate}
                        onSubmit={this.handleSubmit}
                        enableReinitialize={true}
                    >
                        {({ values, handleSubmit, setFieldValue, errors }) => {
                            // console.log(values);
                            return (
                                <Form style={{ padding: "20px 10px" }}>
                                    <Grid container spacing={3}>
                                        {!invoice.id &&
                                            <SelectBox
                                                md={3}
                                                name="customerId"
                                                label="Customer"
                                                selLabel={"name"}
                                                items={customers}
                                            />
                                        }

                                        <SelectBox
                                            md={3}
                                            name="status"
                                            label="Status"
                                            selLabel={"name"}
                                            items={INVOICE_STATUS}
                                        />
                                        <InputDateBox md={3} name="dueTm" label="Due" />
                                        <InputBox md={3} name="rate.amount"
                                            label="Amount" type="number"
                                            disabled={values.items.length > 0}
                                        />

                                    </Grid>
                                    <Grid container spacing={3}>

                                        <Grid item md="8">
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                color="primary"
                                                onClick={() => {
                                                    const arr = values.items
                                                    arr.push({})
                                                    setFieldValue("items", arr)
                                                }}>Add Item</Button>
                                            {values.items.map((e, index) =>
                                                <Grid container spacing={3} key={index}>
                                                    <InputBox
                                                        name={"desc" + index + 1}
                                                        label={"Desc " + (index + 1)} md={3}
                                                        value={e.desc} onChange={(e) => {
                                                            const eq = values.items[index] || {}
                                                            eq.desc = e.target.value
                                                            values.items[index] = eq
                                                            setFieldValue("items", values.items)
                                                        }} />
                                                    <InputBox name={"quantity" + index + 1}
                                                        label={"Quantity " + (index + 1)}
                                                        md={2}
                                                        type="number"
                                                        value={e.quantity}
                                                        onChange={(e) => {
                                                            const eq = values.items[index] || {}
                                                            eq.quantity = e.target.value
                                                            eq.amount = (eq.price || 0) * (eq.quantity || 0)
                                                            values.items[index] = eq
                                                            let amount = 0
                                                            values.items.forEach(element => {
                                                                amount = amount + parseFloat(element.amount)
                                                            });
                                                            setFieldValue("rate.amount", amount)
                                                            setFieldValue("items", values.items)
                                                        }} />
                                                    <InputBox name={"price" + index + 1} label={"Price " + (index + 1)}
                                                        type="number"
                                                        md={3}
                                                        value={e.price} onChange={(e) => {
                                                            const eq = values.items[index] || {}
                                                            eq.price = e.target.value
                                                            eq.amount = (eq.price || 0) * (eq.quantity || 0)
                                                            values.items[index] = eq
                                                            let amount = 0
                                                            values.items.forEach(element => {
                                                                amount = amount + parseFloat(element.amount)
                                                            });
                                                            setFieldValue("rate.amount", amount)
                                                            setFieldValue("items", values.items)
                                                        }} />
                                                    <InputBox name={"amount" + index + 1} label={"Amount " + (index + 1)}
                                                        type="number"
                                                        md={2}
                                                        disabled={true}
                                                        value={(e.price || 0) * (e.quantity || 0)} onChange={(e) => {
                                                            const eq = values.items[index] || {}
                                                            eq.amount = e.target.value
                                                            values.items[index] = eq
                                                            setFieldValue("items", values.items)
                                                        }} />

                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        color="secondary"
                                                        onClick={() => {
                                                            const arr = values.items
                                                            arr.splice(index, 1);
                                                            setFieldValue("items", arr)
                                                            setFieldValue("desc" + index + 1, "")
                                                            setFieldValue("quantity" + index + 1, "")
                                                            setFieldValue("price" + index + 1, "")
                                                            setFieldValue("amount" + index + 1, "")
                                                            let amount = 0
                                                            values.items.forEach(element => {
                                                                amount = amount + parseFloat(element.amount)
                                                            });
                                                            setFieldValue("rate.amount", amount)
                                                        }} >Delete</Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Grid item md="4">
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                color="primary"
                                                onClick={() => {
                                                    const arr = values.repairs
                                                    arr.push({})
                                                    setFieldValue("repairs", arr)
                                                }}>Add Repair</Button>
                                            {values.repairs.map((e, index) =>
                                                <Grid container spacing={3} key={index}>
                                                    <SelectBox name={"id" + index + 1} label={"Repair " + (index + 1)}
                                                        md={8} items={repairs}
                                                        selLabel={"id"}
                                                        onChange={(e) => {
                                                            const r = values.repairs[index] || {}
                                                            r.id = e.target.value
                                                            values.repairs[index] = r
                                                            setFieldValue("repairs", values.repairs)
                                                        }} />
                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        color="secondary"
                                                        onClick={() => {
                                                            const arr = values.repairs
                                                            arr.splice(index, 1);
                                                            setFieldValue("repairs", arr)
                                                            setFieldValue("id" + index + 1, "")
                                                        }} >Delete</Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        {successMessage && (
                                            <Success message={successMessage} />
                                        )}
                                        {errorMessage && (
                                            <Error message={errorMessage} />
                                        )}
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <DialogActions
                                            className={classes.DialogActions}
                                        >
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                            >
                                                {loading ? "Saving..." : "SAVE"}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                onClick={this.props.handleClose}
                                            >
                                                CLOSE
                                            </Button>
                                        </DialogActions>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountId: _get(state, "authData.accountId"),
        constants: _get(state, "appData.constants", {}),
        errorMessage: _get(state, "equipmentData.error", ""),
        loading: _get(state, "appData.appBusy", false),
        carrierValues: _get(state, "form.equipmentForm.values", {}),
        carriers: _get(state, "carrierData.carriers", []),
        bucket: _get(state, "appData.S3_BUCKET")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createInvoice,
            updateInvoice,
            getRepairs,
            getCustomers
        },
        dispatch
    );

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AddForm)
    )
);
