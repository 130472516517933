import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { getInvoice } from "../../actions/invoice/invoiceActions";
import { formatDate } from "../../actions/momentActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2pdf from "html2pdf.js";

import AddForm from "./AddForm";
import { Button } from "@material-ui/core";

const styles = theme => ({
    addWrapper: {
        padding: 10
    },
    searchText: {
        display: "inline-block",
        marginTop: 10,
        marginLeft: 5
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    }
});

export class Invoice extends React.Component {
    state = {
        open: false,
        invoices: [],
        loading: false,
        limit: 25,
        page: 0
    };

    async componentDidMount() {
        await this.getInvoices();
    }

    handleOpen = () => {
        this.setState({ open: true, invoice: null });
    };

    getInvoices = async () => {
        try {
            this.setState({ loading: true });
            const id = _get(this, "props.match.params.id");

            console.log(this.props.id)
            const invoice = await this.props.getInvoice(id);
            if (invoice) {
                this.setState({ invoice });
            }
            this.setState({ loading: false });
        } catch (e) { }
    };

    handleClose = () => {
        this.setState({ open: false });
        this.getInvoices();
    };

    handleEdit = invoice => {
        this.setState({ open: true, invoice });
    };

    onSearch = ({ searchStatus, customerId }) => {
        this.setState({ searchStatus, customerId }, () => {
            this.getInvoices();
        })
    };

    showMessageDialog = () => {
        var element = document.getElementById('element-to-print');
        var opt = {
            margin: 1,
            filename: 'myfile.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        console.log(element)
        // New Promise-based usage:
        html2pdf().set(opt).from(element).save();

    };

    render() {
        const { classes } = this.props;
        const { open, invoice, loading } = this.state;
        const id = _get(this, "props.match.params.id");

        return (
            <div>
                {open && (
                    <AddForm
                        open={open}
                        invoice={invoice}
                        handleClose={this.handleClose}
                    />
                )}

                {loading ? (
                    <div className={classes.grow}>
                        <CircularProgress />
                    </div>
                ) : <div>
                    Invoice <Button onClick={() => { this.setState({ open: true }) }}>Edit</Button>  <Button onClick={() => { this.showMessageDialog() }}>Print</Button>
                    <div>
                        <div id="element-to-print">
                            Invoice {id}
                        </div>
                    </div>

                </div>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountBaseUrl: _get(state, "authData.accountBaseUrl"),

    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getInvoice,
            formatDate
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Invoice)
);
