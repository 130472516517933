import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import {
    createRepair,
    updateRepair
} from "../../actions/repair/repairActions";
import { getCustomers } from "../../actions/customer/customerActions";
import { getTodos } from "../../actions/todo/todoActions";
import { getInvoices } from "../../actions/invoice/invoiceActions";
import { getEstimates } from "../../actions/estimate/estimateActions";
import { validate } from "./validate";
import Grid from "@material-ui/core/Grid";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";
import { ALL_TODO_TYPES } from "../../constants";

const styles = theme => ({
    wrapper: {},
    centerButtons: {
        padding: 20,
        textAlign: "center"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    DialogActions: {
        padding: 20,
        justifyContent: "center",
        flexGrow: 1
    }
});

export class AddForm extends React.Component {
    state = {
        loading: false
    };

    componentDidMount() {
        this.getCustomers()
        this.getInvoices()
        this.getTodos()
        this.getEstimates()
    }
    getCustomers = async () => {
        try {
            const customers = await this.props.getCustomers();
            if (customers && Array.isArray(customers)) {
                this.setState({ customers });
            }
        } catch (e) { }
    };
    getInvoices = async () => {
        try {
            const invoices = await this.props.getInvoices();
            if (invoices && Array.isArray(invoices)) {
                this.setState({ invoices });
            }
        } catch (e) { }
    };
    getTodos = async () => {
        try {
            const todos = await this.props.getTodos();
            if (todos && Array.isArray(todos)) {
                this.setState({ todos });
            }
        } catch (e) { }
    };
    getEstimates = async () => {
        try {
            const estimates = await this.props.getEstimates();
            if (estimates && Array.isArray(estimates)) {
                this.setState({ estimates });
            }
        } catch (e) { }
    };

    handleSubmit = async values => {
        // console.log(values);

        const repair = this.props.repair || {};

        this.setState({ loading: true });
        try {
            values.accountId = this.props.accountId

            let response;
            if (repair && repair.id) {
                values.id = repair.id;
                response = await this.props.updateRepair(values);
            } else {
                response = await this.props.createRepair(values);
            }

            if (response && response.id) {
                this.setState({ successMessage: "Saved sucessfully" });
                setTimeout(() => this.props.handleClose(), 1000);
            } else {
                this.setState({
                    errorMessage: "Not able to save, please again."
                });
            }
        } catch (e) {
            console.log(e);
            this.setState({ errorMessage: "Not able to save, please again." });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { classes, constants } = this.props;
        const repair = this.props.repair || {};
        const { loading, errorMessage, successMessage, customers = [], estimates = [], todos = [], invoices = [] } = this.state;
        const isSmallScreen = window.innerWidth < 400;

        const initialValues = {
            accountId: "",
            customerId: "",
            estimateId: "",
            invoiceId: "",
            todoId: "",
            status: "",
            type: "",
            notes: "",
            tasks: [],
            ...repair
        };

        return (
            <Dialog
                fullScreen={isSmallScreen}
                fullWidth={true}
                maxWidth={"md"}
                className={classes.wrapper}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography>
                        {repair.id ? "EDIT REPAIR" : "ADD REPAIR"}
                    </Typography>
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validate={validate}
                        onSubmit={this.handleSubmit}
                        enableReinitialize={true}
                    >
                        {({ values, handleSubmit, setFieldValue, errors }) => {
                            console.log(errors);
                            return (
                                <Form style={{ padding: "20px 10px" }}>
                                    <Grid container spacing={3}>
                                        <SelectBox
                                            md={3}
                                            name="status"
                                            label="Status"
                                            selLabel={"id"}
                                            items={constants.ALL_STATUS}
                                        />
                                        <SelectBox
                                            md={3}
                                            name="type"
                                            label="Type"
                                            selLabel={"id"}
                                            items={ALL_TODO_TYPES}
                                        />

                                        <InputBox name="notes" label="Notes" md={3} />
                                        <SelectBox
                                            md={3}
                                            name="customerId"
                                            label="Customer"
                                            selLabel={"name"}
                                            items={customers}
                                        />
                                        <SelectBox
                                            md={3}
                                            name="estimateId"
                                            label="Estimate"
                                            selLabel={"id"}
                                            items={estimates}
                                        />
                                        <SelectBox
                                            md={3}
                                            name="invoiceId"
                                            label="Invoice"
                                            selLabel={"id"}
                                            items={invoices}
                                        />
                                        <SelectBox
                                            md={3}
                                            name="todoId"
                                            label="Todo"
                                            selLabel={"id"}
                                            items={todos}
                                        />


                                        {successMessage && (
                                            <Success message={successMessage} />
                                        )}
                                        {errorMessage && (
                                            <Error message={errorMessage} />
                                        )}
                                        <DialogActions
                                            className={classes.DialogActions}
                                        >
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                            >
                                                {loading ? "Saving..." : "SAVE"}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                onClick={this.props.handleClose}
                                            >
                                                CLOSE
                                            </Button>
                                        </DialogActions>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountId: _get(state, "authData.accountId"),
        constants: _get(state, "appData.constants", {}),
        errorMessage: _get(state, "equipmentData.error", ""),
        loading: _get(state, "appData.appBusy", false),
        carrierValues: _get(state, "form.equipmentForm.values", {}),
        carriers: _get(state, "carrierData.carriers", []),
        bucket: _get(state, "appData.S3_BUCKET")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createRepair,
            updateRepair,
            getCustomers,
            getInvoices,
            getEstimates,
            getTodos
        },
        dispatch
    );

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AddForm)
    )
);
