// import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import AppsIcon from "@material-ui/icons/Apps";
import NavigatorLinks from "./NavigatorLinks";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ListIcon from "@material-ui/icons/List";
import RepairIcon from "@material-ui/icons/Settings";
import GroupIcon from "@material-ui/icons/Group";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PersonIcon from '@material-ui/icons/Person';
import FileIcon from '@material-ui/icons/FileCopyOutlined';
// import { LOGO_WB } from "../utils/logos";

const appRoutes = [
    {
        id: "Dashboard",
        label: "Dashboard",
        url: "/dashboard",
        children: [
            {
                id: "Dashboard",
                label: "Dashboard",
                icon: <AppsIcon />,
                url: "/dashboard",
                admin: true,
                manager: false,
                customer: false
            },
            {
                id: "Account",
                label: "Accounts",
                icon: <ListIcon />,
                url: "/accounts",
                admin: true,
                manager: false,
                customer: false
            },
            {
                id: "Admin Users",
                label: "Users",
                icon: <PersonIcon />,
                url: "/admin-users",
                admin: true,
                manager: false,
                customer: false
            },
            // {
            //     id: "Dashboard",
            //     label: "Dashboard",
            //     icon: <PieChartIcon />,
            //     url: "/overview",
            //     // admin: true // Admin only route
            // },
            {
                id: "Dashboard",
                label: "Dashboard",
                icon: <AppsIcon />,
                url: "/",
                manager: true,
                customer: true
            },
            {
                id: "Customer",
                label: "Customers",
                icon: <PersonIcon />,
                active: true,
                url: "/customer/view",
                manager: true,
                customer: false
            },
            {
                id: "Equipment",
                label: "Equipment",
                icon: <LocalShippingIcon />,
                active: true,
                url: "/equipment/view",
                manager: true,
                customer: true
            },
            {
                id: "Invoice",
                label: "Invoice",
                icon: <ReceiptIcon />,
                url: "/invoice/view",
                manager: true,
                customer: false
            },
            {
                id: "Repair",
                label: "Repair",
                icon: <RepairIcon />,
                url: "/repair/view",
                manager: true,
                customer: false
            },
            {
                id: "Estimate",
                label: "Estimate",
                icon: <FileIcon />,
                url: "/estimate/view",
                manager: true,
                customer: false
            },
            {
                id: "Todo",
                label: "Todo",
                icon: <ListIcon />,
                url: "/todo/view",
                manager: true,
                customer: false
            },
            {
                id: "Users",
                label: "Users",
                icon: <GroupIcon />,
                url: "/user/view",
                manager: true,
                customer: false
            }
        ]
    }
    // {
    //     id: "Settings",
    //     label: "Settings",
    //     url: "/dashboard",
    //     children: [
    //         {
    //             id: "Settings",
    //             label: "Settings",
    //             icon: <SettingsIcon />,
    //             url: "/settings/carrier",
    //             manager: true
    //         }
    //     ]
    // }
];

const styles = theme => ({
    categoryHeader: {
        paddingTop: 10,
        paddingBottom: 10
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white
    },
    headerLogo: {
        width: 55,
        margin: "auto"
    },
    item: {
        paddingTop: 4,
        paddingBottom: 4,
        color: "rgba(255, 255, 255, 0.7)"
    },
    itemCategory: {
        backgroundColor: "#171F2A",
        boxShadow: "0 -1px 0 #404854 inset",
        paddingTop: 16,
        paddingBottom: 16
    },
    firebase: {
        fontSize: 24,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.white
    },
    itemActionable: {
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.08)"
        }
    },
    itemActiveItem: {
        color: "#4fc3f7"
    },
    itemPrimary: {
        color: "inherit",
        fontSize: theme.typography.fontSize,
        "&$textDense": {
            fontSize: theme.typography.fontSize
        }
    },
    textDense: {},
    divider: {
        marginTop: theme.spacing(2)
    }
});

class MainNavigator extends React.Component {
    state = {};

    render() {
        const { classes, onClose, ...other } = this.props;

        return (
            <Drawer anchor="left" variant="permanent" {...other}>
                <List disablePadding>
                    <ListItem
                        className={classNames(
                            classes.firebase,
                            classes.item,
                            classes.itemCategory
                        )}
                    >
                        {/*<img
                            src={LOGO_WB}
                            alt={"Business"}
                            style={{ maxWidth: 105, margin: "auto" }}
                        />*/}
                        <div>
                            <span style={{ fontSize: 20 }}>OSRIT</span>
                            <small style={{ fontSize: 10 }}>REPAIR</small>
                        </div>
                    </ListItem>
                    <NavigatorLinks
                        appRoutes={appRoutes}
                        classes={classes}
                        onClose={onClose}
                    />
                </List>
            </Drawer>
        );
    }
}

MainNavigator.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({});

// export default withRouter(withStyles(styles)(connect(mapStateToProps, {})(MainNavigator)));
export default withStyles(styles)(
    connect(
        mapStateToProps,
        {}
    )(MainNavigator)
);
