import _get from "lodash/get";
import _set from "lodash/set";

const validate = values => {
    const errors = {};
    if (!values.rate || !values.rate?.amount) {
        errors.rate = errors.rate || {}
        errors.rate.amount = "Required";
    }
    if (!values.status) {
        errors.status = "Required";
    }

    return errors;
};

const validateEdit = values => {
    const errors = {};
    const requiredFields = [
        "amount",
        "status"
    ];
    requiredFields.forEach(field => {
        let _value = _get(values, `${field}`);
        if (!_value) {
            _set(errors, `${field}`, "Required");
        }
    });

    return errors;
};

const validateProperties = values => {
    const errors = {};

    if (!values.bucket) {
        errors.bucket = "Required";
    }

    return errors;
};

export { validate, validateEdit, validateProperties };
export default validate;
