import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import NotFoundTableRow from "../NotFoundTableRow";
import { formatCurrency } from "../../utils/numberHelpers";
import { Link } from "react-router-dom";

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    capitalize: {
        textTransform: "capitalize"
    },
    icon: {
        color: "#009be5",
        fontSize: 16,
        marginBottom: -2,
        width: 25
    },
    title: {
        fontWeight: 600,
        margin: "10px auto 5px"
    },
    EditIcon: {
        color: "#009be5",
        cursor: "pointer"
    },
    dataXLargeRow: {
        minWidth: 200
    },
    dataLargeRow: {
        minWidth: 175
    },
    dataMedRow: {
        minWidth: 125
    },
    dataSmallRow: {
        minWidth: 100
    },
    span: {
        minWidth: 100,
        display: "inline-block"
    }
});

class ReduxTableRow extends React.Component {
    render() {
        const { formatDate, classes, item = {}, accountBaseUrl } = this.props;
        console.log(accountBaseUrl)
        const items = item.items || [];
        const rate = item.rate || {};
        const amount = rate.amount ? formatCurrency(rate.amount) : "$0.00";
        const paid = rate.paid ? formatCurrency(rate.paid) : "$0.00";
        const balance = rate.balance ? formatCurrency(rate.balance) : "$0.00";
        return (
            <TableRow hover>
                <TableCell component="th" scope="row">
                    <Link to={`${accountBaseUrl}/invoice/${item.id}`}>{item.id}</Link>
                </TableCell>
                <TableCell component="th" scope="row">
                    {item.customer ? item.customer.name : ""}
                </TableCell>
                <TableCell component="th" scope="row">
                    {item.status}
                </TableCell>
                <TableCell component="th" scope="row">
                    <div><span className={classes.span}>Amount:</span> {amount}</div>
                    <div><span className={classes.span}>Paid:</span> {paid}</div>
                    <div><span className={classes.span}>Balance:</span> {balance}</div>
                </TableCell>
                <TableCell>
                    {items.map((subitem, idx) => (
                        <div key={`${idx}`}>
                            <div>
                                <span className={classes.span}>{subitem.desc}</span>{" "}
                                {formatCurrency(subitem.amount)}
                            </div>
                        </div>
                    ))}
                </TableCell>
                <TableCell>
                    <div>Duedate:{formatDate(item.dueTm)}</div>
                    <div>Created:{formatDate(item.createdTm)}</div>
                </TableCell>
                <TableCell>
                    <EditIcon
                        className={classes.EditIcon}
                        onClick={e => this.props.handleEdit(item)}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export class CarrierTable extends React.Component {
    render() {
        const { eld, classes, items = [], handleEdit, accountBaseUrl } = this.props;

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Invoice</TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Rate</TableCell>
                            <TableCell>Details</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(items) &&
                            items.map((item, i) => (
                                <ReduxTableRow
                                    item={item}
                                    accountBaseUrl={accountBaseUrl}
                                    key={i}
                                    eld={eld}
                                    classes={classes}
                                    handleEdit={handleEdit}
                                    formatDate={this.props.formatDate}
                                />
                            ))}
                    </TableBody>
                </Table>
                <NotFoundTableRow items={items} />
            </Paper>
        );
    }
}

CarrierTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CarrierTable);
