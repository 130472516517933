import axios from "../axios";
import _get from "lodash/get";
import * as TYPES from "./userActionTypes";

export const setUsers = value => ({
    type: TYPES.USERS,
    value
});

export function getUserCount(params = {}) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");

        try {
            const _response = await axios({
                url: `${BASE_URL}/secure/${accountId}/users/count`,
                method: "get",
                params
            });

            const response = _get(_response, "data", {});
            return response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return { error: true, message };
        }
    };
}

export function getUsers(params = {}) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");
        try {
            const _response = await axios({
                url: `${BASE_URL}/secure/${accountId}/users`,
                method: "get",
                params
            });

            const response = _get(_response, "data", []);

            dispatch(setUsers(response));
            return response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return { error: true, message };
        }
    };
}

export function createUser(data) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");
        try {
            const response = await axios({
                url: `${BASE_URL}/secure/${accountId}/users`,
                timeout: 20000,
                method: "post",
                data
            });
            const _response = _get(response, "data", {});

            return _response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return { error: true, message };
        }
    };
}

export function updateUser(data) {
    return async (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");
        try {
            const response = await axios({
                url: `${BASE_URL}/secure/${accountId}/users`,
                timeout: 20000,
                method: "put",
                data
            });
            const _response = _get(response, "data", {});

            return _response;
        } catch (error) {
            const message = _get(error, "response.data.errors[0].message");
            return { error: true, message };
        }
    };
}
