import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import queryString from "query-string";
import * as cookie from "react-cookie";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import packageJson from "../../../package.json";
import InputBox from "../../components/InputBox";
import {
    signIn,
    getProfile,
    signOut,
    updateProfile
} from "../../actions/auth/authActions";
import validate from "./validate";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Error from "../Error";
import TermsDialog from "./TermsDialog";
import ForgetDialog from "./ForgetDialog";
import UpdatePasswordDialog from "./UpdatePasswordDialog";
import Typography from "@material-ui/core/Typography";
// import { LOGO_BB } from "../../utils/logos";
import { getToken, fMessaging } from "../../firebase";

const cookies = new cookie.Cookies();

let token;
let userId;
try {
    const values = queryString.parse(window.location.search);
    token = values.token;
    userId = values.id;
} catch (e) { }

export class LogIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            count: 0,
            token,
            userId,
            timeOutId: null,
            isTermsDialog: false,
            signInError: "",
            firebaseToken: null
        };
        if (token) {
            cookies.set("token", token, {
                path: "/",
                maxAge: 43200
            });
        }
    }

    componentDidMount() { }

    componentWillUnmount() { }

    updateFirebaseToken = async () => {
        try {
            if (fMessaging.isSupported()) {
                const fcmWebToken = await getToken();
                console.log("fcmWebToken :::::: ", fcmWebToken);
                if (fcmWebToken) {
                    const params = {
                        fcmWebToken,
                        platform: "WEB"
                    };
                    await this.props.updateProfile(params);
                }
            } else {
                console.log("fMessaging :: ", "not supported");
            }
        } catch (e) {
            console.log("setFirebaseToken :: ", e);
        }
    };

    logIn = async values => {
        this.setState({ loading: true });
        const response = await this.props.signIn(values);
        await this.props.getProfile();

        const isToken = response && response.access_token;
        const isTerms = response && response.terms; //response && response.user && !response.user.terms;

        if (isToken && !isTerms) {
            return this.renderDialog();
        } else if (isToken) {
            await this.updateFirebaseToken();
            this.sucessLogin();
        } else {
            this.setState({
                signInError: "Invalid email or password",
                loading: false
            });
        }
    };

    sucessLogin = () => {
        // console.log(this.props);
        // const {
        //     isAdmin,
        //     isPartner,
        //     isManager,
        //     isMechanic,
        //     isCustomer,
        //     isGuest
        // } = this.props;
        this.setState({ loading: false });
        this.props.history.push(this.props.accountBaseUrl);

        // if (isManager && accountBaseUrl) {
        //     this.props.history.push(`${accountBaseUrl}/status/view`);
        // } else if (isAdmin && accountBaseUrl) {
        //     this.props.history.push(`/dashboard/overview`);
        // } else if (isDriver && accountBaseUrl) {
        //     this.props.history.push(
        //         `${accountBaseUrl}/status/diary/${driverId}`
        //     );
        // } else {
        //     this.setState({ signInError: "Invalid email or password" });
        // }
    };

    renderDialog = () => {
        this.setState({ isTermsDialog: true });
    };

    closeTermsDialog = () => {
        this.setState({ isTermsDialog: false });
    };

    declineTerms = () => {
        this.closeTermsDialog();
        this.props.signOut();
    };

    render() {
        const { isTermsDialog, isForgetDialog, signInError } = this.state;
        const { token, userId, loading } = this.state;

        return (
            <React.Fragment>
                {isTermsDialog && (
                    <TermsDialog
                        open={isTermsDialog}
                        onSuccess={this.sucessLogin}
                        decline={this.declineTerms}
                        handleClose={this.closeTermsDialog}
                    />
                )}
                {isForgetDialog && (
                    <ForgetDialog
                        open={isForgetDialog}
                        onClose={() => this.setState({ isForgetDialog: false })}
                    />
                )}
                {token && userId && (
                    <UpdatePasswordDialog
                        token={token}
                        userId={userId}
                        open={!!(token && userId)}
                        onClose={() =>
                            this.setState({ token: null, userId: null })
                        }
                    />
                )}
                <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"center"}
                    alignContent={"center"}
                    style={{ marginTop: "10vh" }}
                >
                    <Grid item xs={10} sm={4}>
                        <Paper style={{ padding: "50px 25px" }}>
                            <Formik
                                initialValues={{ email: "", password: "" }}
                                validate={validate}
                                onSubmit={this.logIn}
                                enableReinitialize={true}
                            >
                                {({ values, handleSubmit, setFieldValue }) => {
                                    return (
                                        <Form style={{ padding: "20px 10px" }}>
                                            <Grid container spacing={3}>
                                                <Grid
                                                    item
                                                    md={12}
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    {/*<img
                                                        src={LOGO_BB}
                                                        alt={"Logo"}
                                                        style={{
                                                            maxWidth: 150
                                                        }}
                                                    />*/}
                                                    <h3>{"Repair Login"}</h3>
                                                </Grid>
                                                <InputBox
                                                    md={12}
                                                    name="email"
                                                    label="Email"
                                                />
                                                <InputBox
                                                    md={12}
                                                    type="password"
                                                    name="password"
                                                    label="Password"
                                                />
                                            </Grid>
                                            {signInError && (
                                                <Error message={signInError} />
                                            )}

                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                                style={{
                                                    marginTop: 45,
                                                    textAlign: "center"
                                                }}
                                            >
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={loading}
                                                    style={{
                                                        minWidth: 250
                                                    }}
                                                >
                                                    {loading
                                                        ? "Loading..."
                                                        : "Log In"}
                                                </Button>
                                            </Grid>
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                                style={{
                                                    marginTop: 15,
                                                    textAlign: "center"
                                                }}
                                            >
                                                <Button
                                                    style={{
                                                        color: "#009be5"
                                                    }}
                                                    onClick={() =>
                                                        this.setState({
                                                            isForgetDialog: true
                                                        })
                                                    }
                                                >
                                                    {"Forget password?"}
                                                </Button>
                                            </Grid>
                                            <Grid
                                                style={{ textAlign: "center" }}
                                            >
                                                <Typography
                                                    className=""
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    <small>{packageJson.version}</small>
                                                </Typography>
                                            </Grid>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </Paper>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        state,
        signInError: _get(state, "authData.signInError", ""),
        isDriver: _get(state, "authData.isDriver", false),
        isAdmin: _get(state, "authData.isAdmin", false),
        isManager: _get(state, "authData.isManager", false),
        driverId: _get(state, "authData.driverId", ""),
        logInValues: _get(state, "form.logInForm.values", {}),
        allErrors: _get(state, "form.logInForm.syncErrors", {}),
        allFields: _get(state, "form.logInForm.fields", {}),
        accountBaseUrl: _get(state, "authData.accountBaseUrl")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            signIn,
            getProfile,
            updateProfile,
            signOut
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LogIn)
);
