import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import NotFoundTableRow from "../NotFoundTableRow";
import TablePagination from "@material-ui/core/TablePagination";

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    capitalize: {
        textTransform: "capitalize"
    },
    icon: {
        color: "#009be5",
        fontSize: 16,
        marginBottom: -2,
        width: 25
    },
    title: {
        fontWeight: 600,
        margin: "10px auto 5px"
    },
    EditIcon: {
        color: "#009be5",
        cursor: "pointer"
    },
    dataXLargeRow: {
        minWidth: 200
    },
    dataLargeRow: {
        minWidth: 175
    },
    dataMedRow: {
        minWidth: 125
    },
    dataSmallRow: {
        minWidth: 100
    }
});

class ReduxTableRow extends React.Component {
    render() {
        const { classes, item = {} } = this.props;
        return (
            <TableRow hover>
                <TableCell component="th" scope="row">
                    <div
                        className={`${classes.dataLargeRow} ${
                            classes.capitalize
                        }`}
                        onClick={e => this.props.handleEdit(item)}
                    >
                        <strong>{item.name}</strong>
                        {item.customer && <div>{`${item.customer.name}`}</div>}
                    </div>
                </TableCell>
                <TableCell component="th" scope="row">
                    {item.status}
                </TableCell>
                <TableCell component="th" scope="row">
                    {item.type && <span>{` (${item.type})`}</span>}
                    {item.detail && item.detail.mdl && (
                        <div className={classes.capitalize}>
                            {item.detail.mdl} {item.detail.mfr}
                        </div>
                    )}
                </TableCell>

                <TableCell>
                    <div className={classes.dataLargeRow}>
                        {item.vin && <div>VIN# {item.vin}</div>}
                        {item.license && item.license.plt && (
                            <div className={classes.capitalize}>
                                {item.license.sta}-{item.license.plt}
                            </div>
                        )}
                    </div>
                </TableCell>
                <TableCell />
                <TableCell>
                    <EditIcon
                        className={classes.EditIcon}
                        onClick={e => this.props.handleEdit(item)}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export class EquipmentTable extends React.Component {
    state = {
        order: "asc"
    };

    handleChangePage = (event, page) => {
        const { limit } = this.props;
        this.props.handleChange({ page, limit });
    };

    handleChangeRowsPerPage = event => {
        const limit = event.target.value;
        const { page } = this.props;
        this.props.handleChange({ page, limit });
    };

    render() {
        const { classes, items = [], count, handleEdit } = this.props;
        const { limit, page } = this.props;

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Contact</TableCell>
                            <TableCell>License</TableCell>
                            <TableCell />
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(items) &&
                            items.map((item, i) => (
                                <ReduxTableRow
                                    item={item}
                                    key={i}
                                    classes={classes}
                                    handleEdit={handleEdit}
                                    formatDate={this.props.formatDate}
                                />
                            ))}
                    </TableBody>
                </Table>
                <NotFoundTableRow items={items} />
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                    component="div"
                    count={count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

EquipmentTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EquipmentTable);
