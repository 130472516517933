import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import LogIn from "../containers/LogIn";
import Customer from "../containers/Customer";
import Equipment from "../containers/Equipment";
import Invoice from "../containers/Invoice";
import InvoiceDetails from "../containers/Invoice/InvoiceDetails";
import DashBoard from "../containers/Dashboard";
import Todo from "../containers/Todo";
import Estimate from "../containers/Estimate";
import Repair from "../containers/Repair";
import User from "../containers/User";
import AdminUser from "../containers/AdminUser";
import Account from "../containers/Account";

const styles = {
    mainContent: {
        flex: 1,
        padding: "1px 0"
    }
};

function MainRoutes(props) {
    const { classes, accountBaseUrl } = props;

    return (
        <main className={classes.mainContent}>
            <Route exact path="/" component={LogIn} />

            <Route exact path={`${accountBaseUrl}`} component={DashBoard} />
            <Route
                exact
                path={`${accountBaseUrl}/customer/view`}
                component={Customer}
            />
            <Route
                exact
                path={`${accountBaseUrl}/equipment/view`}
                component={Equipment}
            />
            <Route
                exact
                path={`${accountBaseUrl}/invoice/view`}
                component={Invoice}
            />
            <Route
                exact
                path={`${accountBaseUrl}/invoice/:id`}
                component={InvoiceDetails}
            />
            <Route
                exact
                path={`${accountBaseUrl}/repair/view`}
                component={Repair}
            />
            <Route
                exact
                path={`${accountBaseUrl}/todo/view`}
                component={Todo}
            />
            <Route
                exact
                path={`${accountBaseUrl}/estimate/view`}
                component={Estimate}
            />
            <Route
                exact
                path={`${accountBaseUrl}/user/view`}
                component={User}
            />

            <Route
                exact
                path={`/dashboard`}
                component={Account}
            />
            <Route
                exact
                path={`/dashboard/accounts`}
                component={Account}
            />
            <Route
                exact
                path={`/dashboard/admin-users`}
                component={AdminUser}
            />

        </main>
    );
}

MainRoutes.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MainRoutes);
